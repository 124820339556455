import React from "react";
import MobileDev from "@/components/OurServices/MobileDev";
import SystemDev from "./SystemDev";
import Webdev from "./WebDev";
import AiDev from "./AiDev";
import OtherDev from "./OtherDev";

const Contents = (content: any) => {
  return content === "tab1" ? (
    // Content 1
    <div>
      <SystemDev />
    </div>
  ) : content === "tab2" ? (
    <div>
      <MobileDev />
    </div>
  ) : content === "tab3" ? (
    <div>
      <Webdev />
    </div>
  ) : content === "tab4" ? (
    <div>
      <AiDev />
    </div>
  ) : content === "tab5" ? (
    <div>
      <OtherDev />
    </div>
  ) : (
    <div>error</div>
  );
};

export default Contents;
