import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function OtherCart() {
  return (
    <div>
      <div
        data-aos="fade-up"
        className="mb-[2rem] lg:flex max-w-[900px] mx-auto lg:h-[22rem] overflow-hidden w-full border-l-[10px] border-orange  bg-white dark:bg-gray"
      >
        <div>
          <StaticImage
            className="basis-[55%] w-full md:max-w-[600px] h-full"
            placeholder="tracedSVG"
            src="../../static/services/other/sys_admin.png"
            alt=""
          />
        </div>
        <div className="basis-[45%] p-[1.5rem]">
          <h2 className="mb-[1rem] w-[90%] text-[22px] leading-[2rem]">
            System administration
          </h2>
          <p className="md:text-[16px]">
            Our sysadmins will make sure your organization&#39;s computer
            systems are functioning and meet the needs of the organization. They
            will support, troubleshoot, and maintain computer servers and
            networks.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="mb-[2rem] lg:flex max-w-[900px] mx-auto lg:h-[22rem] overflow-hidden w-full border-l-[10px] border-orange  bg-white dark:bg-gray"
      >
        <div>
          <StaticImage
            className="basis-[55%] w-full md:max-w-[600px] h-full"
            placeholder="tracedSVG"
            src="../../static/services/other/consultation.png"
            alt=""
          />
        </div>
        <div className="basis-[45%] p-[1.5rem]">
          <h2 className="mb-[1rem] w-[90%] text-[22px] leading-[2rem]">
            Consultation
          </h2>
          <p className="md:text-[16px]">
            We work in partnership with clients, advising them how to use
            information technology in order to meet their business objectives or
            overcome problems. We work to improve the structure and efficiency
            of IT systems, provide strategic guidance to clients about
            technology and IT infrastructures and will enable major business
            processes through enhancements to IT.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="mb-[2rem] lg:flex max-w-[900px] mx-auto lg:h-[22rem] overflow-hidden w-full border-l-[10px] border-orange  bg-white dark:bg-gray"
      >
        <div>
          <StaticImage
            className="basis-[55%] w-full md:max-w-[600px] h-full"
            placeholder="tracedSVG"
            src="../../static/services/other/hardware.png"
            alt=""
          />
        </div>
        <div className="basis-[45%] p-[1.5rem]">
          <h2 className="mb-[1rem] w-[90%] text-[22px] leading-[2rem]">
            Hardware
          </h2>
          <p className="md:text-[16px]">
            Our hardware engineers research, design, develop, and test computer
            systems and components such as processors, circuit boards, memory
            devices, networks, and routers. By creating new directions in
            computer hardware, these engineers create rapid advances in computer
            technology.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="mb-[2rem] lg:flex max-w-[900px] mx-auto lg:h-[22rem] overflow-hidden w-full border-l-[10px] border-orange  bg-white dark:bg-gray"
      >
        <div>
          <StaticImage
            className="basis-[55%] w-full md:max-w-[600px] h-full"
            placeholder="tracedSVG"
            src="../../static/services/other/uiux.png"
            alt=""
          />
        </div>
        <div className="basis-[45%] p-[1.5rem]">
          <h2 className="mb-[1rem] w-[90%] text-[22px] leading-[2rem]">
            UI/UX
          </h2>
          <p className="md:text-[16px]">
            We create the user interface for an app, website, or other
            interactive media. We will collaborate with product managers and
            engineers to gather requirements from users before designing ideas
            that can be communicated using storyboards.
          </p>
        </div>
      </div>
    </div>
  );
}
