import React from "react";
import { useGlobalState, setGlobalState } from "@/state";

import Layout from "@/components/Layout";
import Contents from "@/components/OurServices/Contents";

export default function OurServices({ location }: any) {
  const tlStyling = `w-full px-[10px] py-[5px] hover:text-white dark:text-white text-black md:rounded-tl-xl border-b-[1px] border-orange py-[1%] text-center hover:bg-orange md:border-0`;
  const tlActiveStyling = `w-full px-[10px] py-[5px] dark:text-white text-white md:rounded-tl-xl border-b-[1px] border-orange py-[1%] text-center bg-orange md:border-0`;
  const trStyling = `w-full px-[10px] py-[5px] hover:text-white dark:text-white text-black md:rounded-tr-xl border-b-[1px] border-orange py-[1%] text-center hover:bg-orange md:border-0`;
  const trActiveStyling = `w-full px-[10px] hover:text-white py-[5px] dark:text-white text-white md:rounded-tr-xl border-b-[1px] border-orange py-[1%] text-center bg-orange md:border-0`;
  const NavigationStyling = `w-full px-[10px] py-[5px] dark:text-white text-black hover:text-white border-b-[1px] border-orange py-[1%] text-center hover:bg-orange md:border-0`;
  const ActiveNavigationStyling = `w-full px-[10px] py-[5px] hover:text-white dark:text-white text-white border-b-[1px] border-orange py-[1%] text-center bg-orange md:border-0`;

  const handleTabs = (e: any) => {
    setGlobalState("services", e);
  };

  const defaultTab = useGlobalState("services");

  return (
    <Layout title="Our services">
      {/* Navigation */}
      <div className="xl:container px-[20px] xl:px-0">
        <div className="flex flex-col w-full justify-between mx-auto md:border-b-[2px] border-orange font-[200] md:flex-row mb-[100px] lg:container xl:px-0">
          <button
            onClick={() => {
              handleTabs("tab1");
            }}
            className={defaultTab[0] !== "tab1" ? tlStyling : tlActiveStyling}
          >
            <p className="md:font-[500] font-[600]">System Development</p>
          </button>
          <button
            onClick={() => {
              setGlobalState("services", "tab2");
            }}
            className={
              defaultTab[0] !== "tab2"
                ? NavigationStyling
                : ActiveNavigationStyling
            }
          >
            <p className="md:font-[500] font-[600]">Mobile Development</p>
          </button>
          <button
            onClick={() => {
              handleTabs("tab3");
            }}
            className={
              defaultTab[0] !== "tab3"
                ? NavigationStyling
                : ActiveNavigationStyling
            }
          >
            <p className="md:font-[500] font-[600]">Web Development</p>
          </button>
          <button
            onClick={() => {
              handleTabs("tab4");
            }}
            className={
              defaultTab[0] !== "tab4"
                ? NavigationStyling
                : ActiveNavigationStyling
            }
          >
            <p className="md:font-[500] font-[600]">AI Development</p>
          </button>
          <button
            onClick={() => {
              handleTabs("tab5");
            }}
            className={defaultTab[0] !== "tab5" ? trStyling : trActiveStyling}
          >
            <p className="md:font-[500] font-[600]">Other</p>
          </button>
        </div>
      </div>

      {/* Content */}
      <div>{Contents(defaultTab[0])}</div>
    </Layout>
  );
}
