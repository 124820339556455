import OtherCart from "@/components/Carts/OtherCart";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const OtherDev = () => {
  return (
    <div>
      <div className="relative">
        <StaticImage
          src="../../../static/services/Ellipse-right.png"
          alt=""
          placeholder="blurred"
          className="!absolute right-0 z-0  md:opacity-100 opacity-50"
        />
      </div>
      <div className="lg:container px-[20px] xl:px-0 relative z-20">
        <div className="flex-row relative gap-5 md:flex">
          <div className="basis-[55%] relative mb-[2rem]">
            <StaticImage
              width={500}
              src="../../../static/services/other.png"
              alt="mobile"
              data-aos="fade-right"
              placeholder="blurred"
              className="md:!absolute top-0 left-0 block"
            />
          </div>
          <section data-aos="fade-left" className="basis-[45%]">
            <h1 className="">
              Other <br />
              <span className="text-orange">development</span>
            </h1>
            <p className="">
              Information technology consultation, hardware research, UI/UX
              designing services aims to advise organizations on how to best use
              information technology to achieve their business objectives.
            </p>
          </section>
        </div>
      </div>

      <div className="relative mt-[11rem]">
        <OtherCart />
      </div>
    </div>
  );
};

export default OtherDev;
