import React from "react";
interface Props {
  title: string;
  text?: string;
}

const AiCart: React.FC<Props> = ({ title, text }) => {
  return (
    <div
      data-aos="fade-up"
      className="my-4 mx-auto aspect-square max-w-[304px]  bg-white dark:bg-grayDark py-7 px-9 md:my-10"
    >
      <h4 className="w-fit border-b-2 border-minorGray pb-2 text-[22px] font-[700] leading-12">
        {title}
      </h4>
      <p className="pt-5">{text}</p>
    </div>
  );
};

export default AiCart;
