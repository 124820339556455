import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";

const Webdev = () => {
  const [activeTab, setActiveTab] = useState("media");
  const NavigationStyling = `rounded-full mb-[1rem] border-transparent py-[5px] text-[23px] px-[20px] text-left hover:bg-orange hover:text-white`;
  const ActiveNavigationStyling = `rounded-full text-white mb-[1rem] border-white py-[5px] text-[23px] px-[20px] text-left bg-orange`;

  return (
    <div>
      <div className="relative z-0">
        <StaticImage
          src="../../../static/services/Ellipse-right.png"
          alt=""
          placeholder="blurred"
          data-aos="fade-left"
          className="!absolute right-0 z-0  md:opacity-100 opacity-50"
        />
      </div>
      <div className="lg:container px-[20px] xl:px-0 relative z-20">
        <div className="flex-row gap-5 md:flex">
          <section className="basis-[55%] relative mb-[2rem]">
            <StaticImage
              width={500}
              src="../../../static/services/Group.png"
              alt="mobile"
              placeholder="blurred"
              data-aos="fade-right"
              className="md:!absolute top-0 left-0 block"
            />
          </section>
          <section data-aos="fade-left" className="basis-[45%]">
            <h1 className="">
              Web <br />
              <span className="text-orange">development</span>
            </h1>
            <p className="">
              A structured web application with a fully dynamic Content
              Management System (CMS) that can be used by anyone with any level
              of knowledge, without necessarily employing a tech-savvy person,
              to define exactly what the organization wants from its website.
            </p>
          </section>
        </div>

        {/* Select tabs */}
        <div className="lg:flex mt-[4rem] md:mt-[9rem] relative z-20">
          {/* Tabs */}
          <div
            data-aos="fade-right"
            className="lg:w-max justify-center flex lg:flex-col flex-wrap h-max mx-auto lg:mx-0"
          >
            {/* <button
              onClick={() => {
                setActiveTab("technology");
              }}
              className={
                activeTab !== "technology"
                  ? NavigationStyling
                  : ActiveNavigationStyling
              }
            >
              <p>Gaming</p>
            </button> */}
            <button
              onClick={() => {
                setActiveTab("media");
              }}
              className={
                activeTab !== "media"
                  ? NavigationStyling
                  : ActiveNavigationStyling
              }
            >
              <p>Media</p>
            </button>
            <button
              onClick={() => {
                setActiveTab("onlineShop");
              }}
              className={
                activeTab !== "onlineShop"
                  ? NavigationStyling
                  : ActiveNavigationStyling
              }
            >
              <p>Online shop</p>
            </button>
            <button
              onClick={() => {
                setActiveTab("pharmacy");
              }}
              className={
                activeTab !== "pharmacy"
                  ? NavigationStyling
                  : ActiveNavigationStyling
              }
            >
              <p>Pharmacy</p>
            </button>
            <button
              onClick={() => {
                setActiveTab("fashion");
              }}
              className={
                activeTab !== "fashion"
                  ? NavigationStyling
                  : ActiveNavigationStyling
              }
            >
              <p>Fashion</p>
            </button>
            <button
              onClick={() => {
                setActiveTab("music");
              }}
              className={
                activeTab !== "music"
                  ? NavigationStyling
                  : ActiveNavigationStyling
              }
            >
              <p>Music</p>
            </button>
            <button
              onClick={() => {
                setActiveTab("books");
              }}
              className={
                activeTab !== "books"
                  ? NavigationStyling
                  : ActiveNavigationStyling
              }
            >
              <p>Books</p>
            </button>
            <button
              onClick={() => {
                setActiveTab("arena");
              }}
              className={
                activeTab !== "arena"
                  ? NavigationStyling
                  : ActiveNavigationStyling
              }
            >
              <p>Arena</p>
            </button>
            <button
              onClick={() => {
                setActiveTab("sports");
              }}
              className={
                activeTab !== "sports"
                  ? NavigationStyling
                  : ActiveNavigationStyling
              }
            >
              <p>Sports</p>
            </button>
            <button
              onClick={() => {
                setActiveTab("arts");
              }}
              className={
                activeTab !== "arts"
                  ? NavigationStyling
                  : ActiveNavigationStyling
              }
            >
              <p>Arts</p>
            </button>
          </div>

          {/* Content */}
          <div className="lg:pl-[4rem] h-max mt-[2rem] ">
            {activeTab === "media" ? (
              <div className="lg:ml-[35px] text-center">
                <StaticImage
                  src="../../../static/web/media.jpg"
                  alt=""
                  placeholder="blurred"
                  data-aos="fade-left"
                  className="drop-shadow-[-10px_-10px_0px_rgba(229,86,0,1)] rounded-xl md:drop-shadow-[-35px_-35px_0px_rgba(229,86,0,1)] lg:w-[700px] h-max mx-auto lg:mx-0"
                />
              </div>
            ) : activeTab === "onlineShop" ? (
              <div className="lg:ml-[35px] text-center">
                <StaticImage
                  src="../../../static/web/online.jpg"
                  alt=""
                  placeholder="blurred"
                  data-aos="fade-left"
                  className="drop-shadow-[-10px_-10px_0px_rgba(229,86,0,1)] rounded-xl md:drop-shadow-[-35px_-35px_0px_rgba(229,86,0,1)] lg:w-[700px] h-max mx-auto lg:mx-0"
                />
              </div>
            ) : activeTab === "pharmacy" ? (
              <div className="lg:ml-[35px] text-center">
                <StaticImage
                  src="../../../static/web/pharmacy.jpg"
                  alt=""
                  placeholder="blurred"
                  data-aos="fade-left"
                  className="drop-shadow-[-10px_-10px_0px_rgba(229,86,0,1)] rounded-xl md:drop-shadow-[-35px_-35px_0px_rgba(229,86,0,1)] lg:w-[700px] h-max mx-auto lg:mx-0"
                />
              </div>
            ) : activeTab === "fashion" ? (
              <div className="lg:ml-[35px] text-center">
                <StaticImage
                  src="../../../static/web/fashion.jpg"
                  alt=""
                  placeholder="blurred"
                  data-aos="fade-left"
                  className="drop-shadow-[-10px_-10px_0px_rgba(229,86,0,1)] rounded-xl md:drop-shadow-[-35px_-35px_0px_rgba(229,86,0,1)] lg:w-[700px] h-max mx-auto lg:mx-0"
                />
              </div>
            ) : activeTab === "music" ? (
              <div className="lg:ml-[35px] text-center">
                <StaticImage
                  src="../../../static/web/music.jpg"
                  alt=""
                  placeholder="blurred"
                  data-aos="fade-left"
                  className="drop-shadow-[-10px_-10px_0px_rgba(229,86,0,1)] rounded-xl md:drop-shadow-[-35px_-35px_0px_rgba(229,86,0,1)] lg:w-[700px] h-max mx-auto lg:mx-0"
                />
              </div>
            ) : activeTab === "books" ? (
              <div className="lg:ml-[35px] text-center">
                <StaticImage
                  src="../../../static/web/book.jpg"
                  alt=""
                  placeholder="blurred"
                  data-aos="fade-left"
                  className="drop-shadow-[-10px_-10px_0px_rgba(229,86,0,1)] rounded-xl md:drop-shadow-[-35px_-35px_0px_rgba(229,86,0,1)] lg:w-[700px] h-max mx-auto lg:mx-0"
                />
              </div>
            ) : activeTab === "arena" ? (
              <div className="lg:ml-[35px] text-center">
                <StaticImage
                  src="../../../static/web/arena.jpg"
                  alt=""
                  placeholder="blurred"
                  data-aos="fade-left"
                  className="drop-shadow-[-10px_-10px_0px_rgba(229,86,0,1)] rounded-xl md:drop-shadow-[-35px_-35px_0px_rgba(229,86,0,1)] lg:w-[700px] h-max mx-auto lg:mx-0"
                />
              </div>
            ) : activeTab === "sports" ? (
              <div className="lg:ml-[35px] text-center">
                <StaticImage
                  src="../../../static/web/sport.jpg"
                  alt=""
                  placeholder="blurred"
                  data-aos="fade-left"
                  className="drop-shadow-[-10px_-10px_0px_rgba(229,86,0,1)] rounded-xl md:drop-shadow-[-35px_-35px_0px_rgba(229,86,0,1)] lg:w-[700px] h-max mx-auto lg:mx-0"
                />
              </div>
            ) : activeTab === "arts" ? (
              <div className="lg:ml-[35px] text-center">
                <StaticImage
                  src="../../../static/web/arts.jpg"
                  alt=""
                  placeholder="blurred"
                  data-aos="fade-left"
                  className="drop-shadow-[-10px_-10px_0px_rgba(229,86,0,1)] rounded-xl md:drop-shadow-[-35px_-35px_0px_rgba(229,86,0,1)] lg:w-[700px] h-max mx-auto lg:mx-0"
                />
              </div>
            ) : (
              <div>error</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Webdev;
