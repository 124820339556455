import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function Content() {
  return (
    <div className="relative z-20 md:grid my-[4rem] md:grid-cols-2 md:gap-[2rem]">
      <span data-aos="fade-right">
        <div className="relative mb-[2rem] mx-auto max-w-[500px] h-[39rem] md:h-[40rem] w-full rounded-md border-[2px] bg-orange md:mb-[2rem]">
          <div className="p-[2rem] md:pr-[5rem]">
            <p className="text-white font-[600] mb-[1rem] text-[22px]">
              Native
            </p>
            <p className="text-white">
              The default way of developing on mobile is to write native code
              for each device – usually Java for Android and Swift for iOS. This
              can give you the best result, but the problem is that you then
              have two codebases to maintain.
            </p>
            <p className="text-white text-[16px] mt-[1rem] font-[200]">
              Secure, easy-to-use
            </p>
          </div>
          <div className="absolute  bottom-0 left-[50%] w-[130%] -translate-x-[50%]">
            <StaticImage
              placeholder="blurred"
              width={500}
              className="!absolute bottom-0 left-[50%] w-[80%] -translate-x-[50%]"
              src="../../static/services/Mobile2.png"
              alt=""
            />
          </div>
        </div>
      </span>

      <span data-aos="fade-left">
        <div className="relative mb-[2rem] mx-auto max-w-[500px] h-[39rem] md:h-[40rem] w-full rounded-md border-[2px] bg-white dark:bg-theme md:mb-0">
          <div className="p-[2rem] md:pr-[5rem]">
            <p className="dark:text-white font-[600] mb-[1rem] text-[22px]">
              Hybrid
            </p>
            <p className="dark:text-white">
              Hybrid apps are essentially web apps that have been put in a
              native app shell. Once they are downloaded from an app store and
              installed locally, the shell is able to connect to whatever
              capabilities the mobile platform provides through a browser
              that&#39;s embedded in the app.
            </p>
            <p className="dark:text-white text-[16px] mt-[1rem] font-[200]">
              One code, fast
            </p>
          </div>
          <div className="absolute bottom-0 left-[50%] w-[130%] -translate-x-[50%]">
            <StaticImage
              placeholder="blurred"
              width={500}
              className="!absolute bottom-0 left-[50%] w-[80%] -translate-x-[50%]"
              src="../../static/services/Mobile3.png"
              alt=""
            />
          </div>
        </div>
      </span>
    </div>
  );
}
