import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const SystemDev = () => {
  return (
    <div>
      <div className="relative">
        <StaticImage
          src="../../../static/services/Ellipse-right.png"
          alt=""
          placeholder="blurred"
          className="!absolute right-0 z-0  md:opacity-100 opacity-50"
        />
      </div>
      <div className="lg:container px-[20px] xl:px-0 relative z-20">
        <div className="flex-row gap-5 md:flex">
          <section className="basis-[55%] mb-[2rem]">
            <StaticImage
              width={500}
              placeholder="blurred"
              data-aos="fade-right"
              src="../../../static/services/System.png"
              alt="mobile"
            />
            <></>
          </section>
          <section className="basis-[45%]">
            <h1 data-aos="fade-left" className="">
              System <br />
              <span className="text-orange">development</span>
            </h1>
            <p data-aos="fade-left" className="">
              System development refers to optimal system configuration
              depending on the scope of the organization&#39;s activities. We
              will develop for clients in the following steps.
            </p>

            <ul className="list-decimal  mt-[2rem] list-inside">
              <li data-aos="fade-left">
                Understanding and researching clients needs
              </li>
              <li data-aos="fade-left">Identify the needs</li>
              <li data-aos="fade-left">
                Recommending the appropriate system integration
              </li>
              <li data-aos="fade-left">
                Designing a system based on mutual agreement between the parties
              </li>
            </ul>
          </section>
        </div>

        {/* Carts */}
        <div className="grid sm:grid-cols-2 gap-[2rem] mt-[4rem]">
          <div
            data-aos="fade-up"
            className="h-full pb-[1rem] rounded-r-2xl border-l-8 border-orange  bg-white dark:bg-gray pl-[3%] pr-[5%] pt-[5%]"
          >
            <div className="min-w-[100px]">
              <StaticImage
                width={100}
                aspectRatio={1}
                placeholder="blurred"
                src="../../../static/icons/desktop.png"
                alt="icon"
              />
            </div>
            <div>
              <h2 className=" mt-[10%] max-w-fit border-b-4 border-orange  pb-9 text-[22px] font-[700]">
                Desktop application
              </h2>
              <p className="pt-9">
                A desktop application is a software program that can be run on a
                standalone computer to perform a specific task by an end-user.{" "}
                <br /> <br /> Some desktop applications such as word editors,
                photo editing apps and media players allow you to perform
                different tasks while others such as gaming apps are developed
                purely for entertainment.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="h-full pb-[1rem] rounded-r-2xl border-l-8 border-orange  bg-white dark:bg-gray pl-[3%] pr-[5%] pt-[5%]"
          >
            <div className="min-w-[100px]">
              <StaticImage
                width={100}
                aspectRatio={1}
                placeholder="blurred"
                src="../../../static/icons/desktop.png"
                alt="icon"
              />
            </div>
            <div>
              <h2 className=" mt-[10%] max-w-fit border-b-4 border-orange  pb-9 text-[22px] font-[700]">
                CMS
              </h2>
              <p className="pt-9">
                A content management system, often abbreviated as CMS, is
                software that helps users create, manage, and modify content on
                a website without the need for specialized technical knowledge.
                <br />
                <br />
                In simpler language, a content management system is a tool that
                helps you build a website without needing to write all the code
                from scratch (or even know how to code at all).
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="h-full pb-[1rem] rounded-r-2xl border-l-8 border-orange  bg-white dark:bg-gray pl-[3%] pr-[5%] pt-[5%]"
          >
            <div className="min-w-[100px]">
              <StaticImage
                width={100}
                aspectRatio={1}
                placeholder="blurred"
                src="../../../static/icons/desktop.png"
                alt="icon"
              />
            </div>
            <div>
              <h2 className=" mt-[10%] max-w-fit border-b-4 border-orange text-[22px] pb-9 font-[700]">
                Customized system
              </h2>
              <p className="pt-9">
                It is when developers create a custom software product, that
                application is tailored specifically for the commissioning
                organization&#39;s use. For example, if a developer created an
                application for a bank, only the bank or one of its specific
                departments would use the tool.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="h-full pb-[1rem] rounded-r-2xl border-l-8 border-orange  bg-white dark:bg-gray pl-[3%] pr-[5%] pt-[5%]"
          >
            <div className="min-w-[100px]">
              <StaticImage
                width={100}
                aspectRatio={1}
                placeholder="blurred"
                src="../../../static/icons/desktop.png"
                alt="icon"
              />
            </div>
            <div>
              <h2 className=" mt-[10%] max-w-fit border-b-4 border-orange text-[22px] pb-9 font-[700]">
                System architecture
              </h2>
              <p className="pt-9">
                A system architecture is the conceptual model that defines the
                structure, behavior, and more views of a system. An architecture
                description is a formal description and representation of a
                system, organized in a way that supports reasoning about the
                structures and behaviors of the system.
              </p>
            </div>
          </div>
        </div>

        <div className="flex basis-1/2 ">
          <div className="relative z-10">
            <h2
              data-aos="fade-up"
              className="text-[22px] mt-[50px] my-[30px] md:my-[70px]"
            >
              Our Expertise at Your Service{" "}
            </h2>
            <ul className="md:mt-[60px] [&>*]:my-4 md:[&>*]:my-8">
              <li
                data-aos="fade-right"
                className="flex group items-center relative"
              >
                <span className="mr-5 h-[2.5px] min-w-[20px] bg-orange md:min-w-[40px]"></span>
                <p className="relative inline-block  before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] md:border-0">
                  IT project management
                </p>
              </li>
              <li
                data-aos="fade-right"
                className="flex items-center group relative"
              >
                <span className="mr-5 h-[2.5px] min-w-[20px] bg-orange md:min-w-[40px]"></span>
                <p className="relative inline-block  before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] md:border-0">
                  Network enhancement
                </p>
              </li>
              <li
                data-aos="fade-right"
                className="flex items-center group relative"
              >
                <span className="mr-5 h-[2.5px] min-w-[20px] bg-orange md:min-w-[40px]"></span>
                <p className="relative inline-block  before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] md:border-0">
                  Performance enhancement
                </p>
              </li>
              <li
                data-aos="fade-right"
                className="flex items-center group relative"
              >
                <span className="mr-5 h-[2.5px] min-w-[20px] bg-orange md:min-w-[40px]"></span>{" "}
                <p className="relative inline-block  before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] md:border-0">
                  Transition to high availability
                </p>
              </li>
              <li
                data-aos="fade-right"
                className="flex items-center group relative"
              >
                <span className="mr-5 h-[2.5px] min-w-[20px] bg-orange md:min-w-[40px]"></span>{" "}
                <p className="relative inline-block  before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] md:border-0">
                  Productivity tools implementation
                </p>
              </li>
              <li
                data-aos="fade-right"
                className="flex items-center group relative"
              >
                <span className="mr-5 h-[2px] min-w-[20px] bg-orange md:h-[2.5px] md:min-w-[40px]"></span>
                <p className="relative inline-block  before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] md:border-0">
                  Cloud migration (complete or hybrid)
                </p>
              </li>
              <li
                data-aos="fade-right"
                className="flex items-center group relative"
              >
                <span className="mr-5 h-[2.5px] min-w-[20px] bg-orange md:min-w-[40px]"></span>
                <p className="relative inline-block  before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] md:border-0">
                  Expertise in business intelligence (BI)
                </p>
              </li>
            </ul>
            <h2
              data-aos="fade-up"
              className="text-[22px] my-[30px] mt-[50px] md:my-[70px]"
            >
              Our approach
            </h2>
            <ul className="md:mt-[60px] [&>*]:my-4 md:[&>*]:my-8">
              <li
                data-aos="fade-right"
                className="flex items-center group relative"
              >
                <span className="mr-5 h-[2.5px] min-w-[20px] bg-orange md:min-w-[40px]"></span>
                <p className="relative inline-block  before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] md:border-0">
                  Initial meeting: defining needs, expectations and objectives
                </p>
              </li>
              <li
                data-aos="fade-right"
                className="flex items-center group relative"
              >
                <span className="mr-5 h-[2.5px] min-w-[20px] bg-orange md:min-w-[40px]"></span>
                <p className="relative inline-block  before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] md:border-0">
                  Precise and detailed proposition
                </p>
              </li>
              <li
                data-aos="fade-right"
                className="flex items-center group relative"
              >
                <span className="mr-5 h-[2.5px] min-w-[20px] bg-orange md:min-w-[40px]"></span>
                <p className="relative inline-block  before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] md:border-0">
                  Monitoring throughout the project to ensure expectations are
                  met and to make any necessary revisions.
                </p>
              </li>
              <li
                data-aos="fade-right"
                className="flex items-center group relative"
              >
                <span className="mr-5 h-[2.5px] min-w-[20px] bg-orange md:min-w-[40px]"></span>
                <p className="relative inline-block  before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] md:border-0">
                  Follow-up after the project to ensure your satisfaction
                </p>
              </li>
            </ul>
          </div>
          <div className="z-0 relative xl:container mt-[70px] basis-[1/2]">
            <StaticImage
              className="!absolute right-0 opacity-50 md:opacity-70"
              placeholder="blurred"
              src="../../../static/services/other/robot.png"
              alt=""
              width={600}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemDev;
