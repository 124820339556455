import AiCart from "@/components/Carts/AiCart";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const AiDev = () => {
  return (
    <div>
      <div className="relative">
        <StaticImage
          src="../../../static/services/Ellipse-right.png"
          alt=""
          placeholder="blurred"
          className="!absolute right-0 z-0  md:opacity-100 opacity-50"
        />
      </div>
      <div className="lg:container px-[20px] xl:px-0 relative z-20">
        <div className="flex-row gap-5 md:flex">
          <section className="basis-[55%] relative mb-[2rem]">
            <StaticImage
              width={500}
              placeholder="blurred"
              src="../../../static/services/AI.png"
              data-aos="fade-right"
              alt="mobile"
              className="md:!absolute top-0 left-0"
            />
          </section>
          <section data-aos="fade-left" className="basis-[45%]">
            <h1 className="">
              AI
              <span className="text-orange"> development</span>
            </h1>
            <p className="">
              The development of computer systems able to perform tasks normally
              requiring human intelligence, such as visual perception, speech
              recognition, decision-making, and translation between languages.
            </p>
            <ul className="list-disc mt-[2rem] list-inside">
              <li className="text-[16px]">Create architecture</li>
              <li className="text-[16px]">
                Training specially designed modules
              </li>
              <li className="text-[16px]">Data preparation</li>
              <li className="text-[16px]">Development and improvement</li>
            </ul>
          </section>
        </div>

        <div className="flex flex-col md:grid md:grid-cols-3 mt-[4rem]">
          <div className="my-auto block">
            <AiCart
              title="What"
              text="Artificial intelligence is the theory and development of computer programs that are able to do tasks and solve problems that usually require human intelligence."
            />
            <AiCart
              title="When"
              text="Applications of AI can be seen in everyday scenarios such as financial services fraud detection, retail purchase predictions, and online customer support interactions."
            />
          </div>
          <div className="flex flex-col justify-center">
            <AiCart
              title="Who"
              text="Voice assistants, image recognition for face unlock in cellphones are examples of AI software currently being used in everyday life by almost everyone. "
            />
            <div className="mx-auto w-[80%]">
              <StaticImage
                placeholder="blurred"
                src="../../../static/services/other/brain.png"
                alt=""
                width={400}
              />
            </div>
            <AiCart
              title="Where"
              text="This technology is currently deployed in numerous sectors, including transportation, manufacturing, finance, health care, education, and urban planning."
            />
          </div>
          <div className="my-auto block ">
            <AiCart
              title="How"
              text="The first step, training, involves feeding data into a computer algorithm to create predictions and evaluate their accuracy. The second step, validating, evaluates how well the trained model performs on previously unseen data. Finally, testing is done to find out if the final model makes accurate predictions with new data that it has never seen before."
            />
            <AiCart
              title="Why"
              text="Artificial Intelligence enhances the speed, precision and effectiveness of human efforts. "
            />
          </div>
        </div>

        <section className="mb-[4rem] mt-[2rem]">
          <div className="md:grid hidden gap-[2rem]">
            <div className="flex group justify-between relative my-[1rem] items-center">
              <h1 data-aos="fade-up" className="">
                Industry
              </h1>
              <div className="relative flex flex-col align-middle items-center w-[50%]">
                <div className="relative h-[1px] my-auto w-0 transition-width duration-300 ease-in-out group-hover:w-[80%] z-0 bg-orange"></div>
                <div className=" h-[1px] my-auto invisible w-[50%] z-0 bg-orange"></div>
              </div>
              <p data-aos="fade-up" className="relative z-10">
                Intelligent, self-optimizing machines that automate production
                processes. Forecasting efficiency losses for better planning.
                Detecting quality defects to facilitate predictive maintenance.
              </p>
            </div>
            <div className="flex group justify-between relative my-[1rem] items-center">
              <h1
                data-aos="fade-up"
                className="md:text-center relative z-10 md:text-[100px] md:font-[700] text-orange"
              >
                Health
              </h1>
              <div
                data-aos="fade-up"
                className="relative flex flex-col align-middle items-center w-[50%]"
              >
                <div className="relative h-[1px] my-auto w-0 transition-width duration-300 ease-in-out group-hover:w-[80%] z-0 bg-orange"></div>
                <div className=" h-[1px] my-auto invisible w-[50%] z-0 bg-orange"></div>
              </div>
              {/* <div className="relative w-full lg:w-max inline-block lg:mx-[1.4rem] border-b-[1px] border-lightGray border-lightGray  text-[12px] lg:text-[14px] lg:p-0 before:absolute before:right-0 before:bottom-0 lg:before:h-[2px] before:w-[0%] before:bg-orange before:transition-width group-hover:before:left-0 group-hover:before:w-[100%] lg:border-0">
              a
            </div> */}
              <p data-aos="fade-up" className="relative z-10">
                Integrating AI into the healthcare ecosystem allows for a
                multitude of benefits, including automating tasks and analyzing
                big patient data sets to deliver better healthcare faster, and
                at a lower cost.
              </p>
            </div>
            <div className="flex group justify-between relative my-[1rem] items-center">
              <h1 data-aos="fade-up" className="">
                Education
              </h1>
              <div
                data-aos="fade-up"
                className="relative flex flex-col align-middle items-center w-[50%]"
              >
                <div className="relative h-[1px] my-auto w-0 transition-width duration-300 ease-in-out group-hover:w-[80%] z-0 bg-orange"></div>
                <div className=" h-[1px] my-auto invisible w-[50%] z-0 bg-orange"></div>
              </div>
              <p data-aos="fade-up" className="relative z-10">
                In education you can have a variety of time consuming tasks
                diminished or improve learning by using Grading software, Voice
                assistants, Personalized learning, Smart content, Intelligent
                tutoring, Virtual learning environment.
              </p>
            </div>
          </div>

          <div className="grid md:hidden md:grid-cols-2 gap-[2rem]">
            <h1 data-aos="fade-up" className="">
              Industry
            </h1>
            <p data-aos="fade-up">
              Intelligent, self-optimizing machines that automate production
              processes. Forecasting efficiency losses for better planning.
              Detecting quality defects to facilitate predictive maintenance.
            </p>
            <h1
              data-aos="fade-up"
              className="mdLtext-center md:text-[100px] md:font-[700] text-orange"
            >
              Health
            </h1>
            <p data-aos="fade-up">
              Integrating AI into the healthcare ecosystem allows for a
              multitude of benefits, including automating tasks and analyzing
              big patient data sets to deliver better healthcare faster, and at
              a lower cost.
            </p>
            <h1 data-aos="fade-up" className="">
              Education
            </h1>
            <p data-aos="fade-up">
              In education you can have a variety of time consuming tasks
              diminished or improve learning by using Grading software, Voice
              assistants, Personalized learning, Smart content, Intelligent
              tutoring, Virtual learning environment.
            </p>
          </div>
        </section>

        {/* Mongolian AI */}
        <div className="pb-[3rem]">
          <div className="text-center mx-auto">
            <h1 data-aos="fade-up">MONGOLIAN AI</h1>
            <p
              data-aos="fade-up"
              className="max-w-[400px] mx-auto my-[2rem] mb-[3rem]"
            >
              The AI we develop is learning and adapting to the Mongolian
              ecosystem and the people in it, to optimize use in our country.{" "}
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-[2rem]">
            <div
              data-aos="fade-right"
              className="dark:bg-theme bg-white border-[2px] text-center p-[2rem] rounded-xl pb-[3rem] max-w-[350px] mx-auto"
            >
              <p className="mb-[1rem] font-[700]">
                YOUR PERSONAL ARTIFICIAL INTELLIGENCE
              </p>
              <p>
                Solve unique applications and situations that arise only in
                Mongolia.
              </p>
            </div>
            <div
              data-aos="fade-up"
              className="bg-orange dark:border-white border-theme border-[2px] text-white text-center p-[2rem] rounded-xl pb-[3rem] max-w-[350px] mx-auto"
            >
              <p className="mb-[1rem] font-[700]">SELF IMPROVEMENT</p>
              <p>
                The brain can evaluate the decisions it makes with user input
                and improve its performance based on that.
              </p>
            </div>
            <div
              data-aos="fade-left"
              className="dark:bg-theme bg-white border-[2px] text-center p-[2rem] rounded-xl pb-[3rem] max-w-[350px] mx-auto"
            >
              <p className="mb-[1rem] font-[700]">SECURITY</p>
              <p>
                It is completely protected from the common attacks as it is
                impossible for outsiders to know what data and architecture the
                system is made of.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiDev;
