import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import MobileCart from "@/components/Carts/MobileCart";

import { GiPartyPopper, GiChecklist } from "react-icons/gi";
import { RiToolsLine } from "react-icons/ri";
import { BsPencil } from "react-icons/bs";
import { IoSearchOutline, IoSettingsOutline } from "react-icons/io5";
import Logo from "-!svg-react-loader?props[]=className:w-4 h-4!@/static/svg/tactic.svg";

const MobileDev = () => {
  return (
    <div className="">
      <div className="relative">
        <StaticImage
          src="../../../static/services/Ellipse-right.png"
          alt=""
          placeholder="blurred"
          className="!absolute right-0 z-0 md:opacity-100 opacity-50"
        />
      </div>

      <div className="lg:container px-[20px] xl:px-0 relative z-20">
        <div className="flex-row relative z-10 gap-5 md:flex">
          <section className="basis-[55%] relative">
            <StaticImage
              width={500}
              placeholder="blurred"
              data-aos="fade-right"
              src="../../../static/services/Mobile.png"
              alt="mobile"
              className=""
            />
          </section>
          <section data-aos="fade-left" className="basis-[45%]">
            <h1 className="">
              Mobile app <br />
              <span className="text-orange">development</span>
            </h1>
            <p className="">
              Development for all types of smart phones (iphone, android,
              windows, blackberry) and mobile devices (iOS, android, windows)
              means development for mobile phones that can easily receive
              information and make payments.
            </p>
          </section>
        </div>

        <MobileCart />

        <div className="mb-[5rem] text-center">
          <div className="md:block hidden">
            {/* Tactic */}
            <div className="grid grid-cols-6">
              {/* 1 */}
              <div data-aos="fade-down" className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <IoSearchOutline
                    size={30}
                    color="white"
                    className="stroke-1"
                  />
                </div>
                <p className="font-[700]">Analyze</p>
                <p className="text-[12px] opacity-70">
                  We study your requiriments and goals to create the best
                  solution for your needs.
                </p>
              </div>

              <div></div>

              {/* 1 */}
              <div data-aos="fade-down" className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <IoSettingsOutline size={30} color="white" />
                </div>
                <p className="font-[700]">Develop</p>
                <p className="text-[12px] opacity-70">
                  Be it iOS, Android, or both, we utilize the latest tech
                  practice to bring your app to life.
                </p>
              </div>

              <div></div>

              {/* 1 */}
              <div data-aos="fade-down" className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <GiPartyPopper size={30} color="white" />
                </div>
                <p className="font-[700]">Publish</p>
                <p className="text-[12px] opacity-70">
                  We facilitate the app’s launch to ensure your product reaches
                  the right audience on App Store and Google Play.
                </p>
              </div>
            </div>

            <div className="md:flex hidden text-center mx-auto justify-center">
              {/* <StaticImage
                placeholder="blurred"
                className="mx-auto w-[85%] md:block hidden"
                src="../../../static/services/Mobile/tactic.png"
                alt="Tactic"
              /> */}
              <Logo />
            </div>

            <div className="md:hidden block">
              <Logo />
            </div>

            {/* Mobile steps */}
            <div className="grid grid-cols-6">
              <div></div>

              {/* 1 */}
              <div data-aos="fade-up" className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <BsPencil size={30} color="white" />
                </div>
                <p className="font-[700]">Design</p>
                <p className="text-[12px] opacity-70">
                  Based on research findings and best practices, we design
                  intuitive interfaces offering your users an awesome experience
                  with distinctive visuals.
                </p>
              </div>

              <div></div>

              {/* 1 */}
              <div data-aos="fade-up" className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <GiChecklist size={30} color="white" />
                </div>
                <p className="font-[700]">Test</p>
                <p className="text-[12px] opacity-70">
                  From usability to security, our quality assurance team
                  verifies your final product is bug-free on all fronts.
                </p>
              </div>

              <div></div>

              {/* 1 */}
              <div data-aos="fade-up" className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <RiToolsLine size={30} color="white" />
                </div>
                <p className="font-[700]">Support</p>
                <p className="text-[12px] opacity-70">
                  You will receive consistent technical support to maintain and
                  modernize your solution, helping your product remain hot on
                  the market.
                </p>
              </div>
            </div>
          </div>

          {/* Mobile */}
          <div className="md:hidden block">
            {/* Tactic */}
            <div className="grid gap-[2rem] w-full">
              {/* 1 */}
              <div data-aos="fade-up" className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <IoSearchOutline size={30} color="white" />
                </div>
                <p className="font-[700]">Analyze</p>
                <p className="text-[12px] opacity-70">
                  We study your requirements and goals to create the best
                  solution for your needs.
                </p>
              </div>

              {/* 1 */}
              <div data-aos="fade-up" className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <IoSettingsOutline size={30} color="white" />
                </div>
                <p className="font-[700]">Develop</p>
                <p className="text-[12px] opacity-70">
                  We study your requiriments and goals to create the best
                  solution for your needs.
                </p>
              </div>

              {/* 1 */}
              <div data-aos="fade-up" className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <GiPartyPopper size={30} color="white" />
                </div>
                <p className="font-[700]">Publish</p>
                <p className="text-[12px] opacity-70">
                  We study your requiriments and goals to create the best
                  solution for your needs.
                </p>
              </div>

              <div className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <BsPencil size={30} color="white" />
                </div>
                <p className="font-[700]">Design</p>
                <p className="text-[12px] opacity-70">
                  Based on research findings and best practices, we design
                  intuitive interfaces offering your users an awesome experience
                  with distinctive visuals.
                </p>
              </div>

              {/* 1 */}
              <div data-aos="fade-up" className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <GiChecklist size={30} color="white" />
                </div>
                <p className="font-[700]">Test</p>
                <p className="text-[12px] opacity-70">
                  From usability to security, our quality assurance team
                  verifies your final product is bug-free on all fronts.
                </p>
              </div>

              {/* 1 */}
              <div data-aos="fade-up" className="text-center">
                <div className="bg-orange w-max h-max mx-auto p-[1rem] border-[2px] mb-[1rem] rounded-full">
                  <RiToolsLine size={30} color="white" />
                </div>
                <p className="font-[700]">Support</p>
                <p className="text-[12px] opacity-70">
                  You will receive consistent technical support to maintain and
                  modernize your solution, helping your product remain hot on
                  the market.
                </p>
              </div>
            </div>
          </div>

          {/* Text */}
          <p
            data-aos="fade-up"
            className="text-center mx-auto mt-[2rem] lg:w-[80%]"
          >
            You will receive consistent technical support to maintain and
            modernize your solution, helping your product remain hot on the
            market.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileDev;
